var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card mt-5",class:_vm.$style.container},[_vm._m(0),_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"h1",class:_vm.$style.welcomeText},[_vm._v("Selamat datang!")]),_c('div',{staticClass:"h6"},[_vm._v("Buat akun Sistem Rekam Medis Elektronik")])]),_c('button',{staticClass:"text-center w-100 mb-4 d-flex align-items-center justify-content-center",class:_vm.$style.googleButton,attrs:{"size":"large"},on:{"click":_vm.handleGoogleLogin}},[_c('img',{staticClass:"mr-2",attrs:{"src":"resources/images/google-icon.svg","alt":"Google","height":"26"}}),_c('div',{staticClass:"font-size-18 justify-content-center"},[_vm._v("Daftar dengan Google")])]),_c('div',{ref:"googleLoginBtn",attrs:{"hidden":""}}),_c('div',{staticClass:"d-flex align-items-center justify-content-center my-3"},[_c('div',{class:_vm.$style.line}),_c('span',{staticClass:"mx-2"},[_vm._v("atau daftar dengan")]),_c('div',{class:_vm.$style.line})]),_c('a-form',{staticClass:"mb-4",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v("Nama Lengkap"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              initialValue: '',
              rules: [
                { required: true, message: 'Masukkan nama lengkap Anda!' } ],
            } ]),expression:"[\n            'name',\n            {\n              initialValue: '',\n              rules: [\n                { required: true, message: 'Masukkan nama lengkap Anda!' },\n              ],\n            },\n          ]"}],attrs:{"size":"default","placeholder":"Nama lengkap"}})],1),_c('a-form-item',[_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v("Tipe Fasilitas Pelayanan Kesehatan"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'tipeFasyankes',
              {
                initialValue: '',
                rules: [
                  { required: true, message: 'Pilih tipe fasilitas pelayanan kesehatan Anda!' } ],
              } ]),expression:"[\n              'tipeFasyankes',\n              {\n                initialValue: '',\n                rules: [\n                  { required: true, message: 'Pilih tipe fasilitas pelayanan kesehatan Anda!' },\n                ],\n              },\n            ]"}],attrs:{"size":"default","placeholder":"Pilih tipe fayankes"},on:{"change":_vm.handleFayankesTypeClick}},[_c('a-select-option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("Pilih tipe fayankes")]),_vm._l((_vm.fayankesList),function(fayankes){return _c('a-select-option',{key:fayankes,attrs:{"value":fayankes}},[_vm._v(" "+_vm._s(fayankes)+" ")])})],2)],1),_c('a-form-item',[_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v("Nama Fasilitas Pelayanan Kesehatan"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'medical_facility_id',
              {
                initialValue: '',
                rules: [
                  { required: true, message: 'Masukkan nama fasilitas pelayanan kesehatan Anda!' } ],
              } ]),expression:"[\n              'medical_facility_id',\n              {\n                initialValue: '',\n                rules: [\n                  { required: true, message: 'Masukkan nama fasilitas pelayanan kesehatan Anda!' },\n                ],\n              },\n            ]"}],attrs:{"show-search":"","placeholder":"Nama fasyankes","option-filter-prop":"children","filterOption":function (input, option) { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }}},[_c('a-select-option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("Nama fasyankes")]),_vm._l((_vm.medicalFacility),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])})],2)],1),_c('a-form-item',[_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v("Role Akun"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'roles',
              {
                // initialValue: null,
                rules: [
                  { required: true, message: 'Pilih role Anda!' } ],
              } ]),expression:"[\n              'roles',\n              {\n                // initialValue: null,\n                rules: [\n                  { required: true, message: 'Pilih role Anda!' },\n                ],\n              },\n            ]"}],attrs:{"mode":"multiple","size":"default","placeholder":"Pilih role"},model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}},[_c('a-select-option',{attrs:{"value":"dokter"}},[_vm._v("Dokter")]),_c('a-select-option',{attrs:{"value":"perawat"}},[_vm._v("Perawat")])],1)],1),(this.isDoctorRole())?_c('a-form-item',[_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v("Spesialisasi (Layanan)"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'specialization_id',
              {
                initialValue: '',
                rules: [
                  { required: true, message: 'Pilih spesialisasi Anda!' } ],
              } ]),expression:"[\n              'specialization_id',\n              {\n                initialValue: '',\n                rules: [\n                  { required: true, message: 'Pilih spesialisasi Anda!' },\n                ],\n              },\n            ]"}],attrs:{"size":"default","placeholder":"Pilih Spesialisasi"}},[_c('a-select-option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("Pilih spesialisasi dokter")]),_vm._l((_vm.doctorSpecializations),function(specialization){return _c('a-select-option',{key:specialization.id,attrs:{"value":specialization.id}},[_vm._v(" "+_vm._s(specialization.name)+" ")])})],2)],1):_vm._e(),_c('a-form-item',[_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v("Email"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              initialValue: '',
              rules: [
                { required: true, message: 'Masukkan email Anda!' },
                { type: 'email', message: 'The input is not valid E-mail!' } ],
            } ]),expression:"[\n            'email',\n            {\n              initialValue: '',\n              rules: [\n                { required: true, message: 'Masukkan email Anda!' },\n                { type: 'email', message: 'The input is not valid E-mail!' },\n              ],\n            },\n          ]"}],attrs:{"size":"default","placeholder":"Email"}})],1),_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v("Nomor Telepon Selular"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"d-flex"},[_c('a-form-item',{staticClass:"col-xl-2"},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone_no_code', { initialValue: '+62', rules: [ { required: true, message: 'Kode negara harus dipilih' }],}]),expression:"['phone_no_code', { initialValue: '+62', rules: [ { required: true, message: 'Kode negara harus dipilih' }],}]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Kode Negara"}},_vm._l((_vm.phoneCode),function(code){return _c('a-select-option',{key:code.country,attrs:{"value":code.code}},[_vm._v(" "+_vm._s(code.code)+" ")])}),1)],1),_c('a-form-item',{staticClass:"col-xl-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'phone_no',
              {
                initialValue: '',
                rules: [
                  { required: true, message: 'Masukkan nomor telepon Anda!' },
                  { pattern: /^\d{5,}$/, message: 'Nomor telepon tidak valid' } ],
              } ]),expression:"[\n              'phone_no',\n              {\n                initialValue: '',\n                rules: [\n                  { required: true, message: 'Masukkan nomor telepon Anda!' },\n                  { pattern: /^\\d{5,}$/, message: 'Nomor telepon tidak valid' },\n                ],\n              },\n            ]"}],attrs:{"size":"default","placeholder":"0000000000","autocomplete":"tel"}})],1)],1),_c('div',{staticClass:"d-flex flex-row justify-content-between"},[_c('a-form-item',[_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v("Kata Sandi"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {
                initialValue: '',
                rules: [
                  { required: true, message: 'Masukkan kata sandi Anda!' },
                  { min: 8, message: 'Kata sandi minimal 8 karakter!'}
                ],
              } ]),expression:"[\n              'password',\n              {\n                initialValue: '',\n                rules: [\n                  { required: true, message: 'Masukkan kata sandi Anda!' },\n                  { min: 8, message: 'Kata sandi minimal 8 karakter!'}\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"size":"default","placeholder":"Kata sandi","autocomplete":"new-password"}}),_c('p',{staticClass:"small",staticStyle:{"height":"8px"}},[_vm._v("Kata sandi minimal 8 karakter")])],1),_c('a-form-item',[_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v("Konfirmasi Kata Sandi"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'confirmPassword',
              {
                initialValue: '',
                rules: [
                  { required: true, message: 'Konfirmasi kata sandi Anda!' },
                  { validator: this.checkPassword } ],
              } ]),expression:"[\n              'confirmPassword',\n              {\n                initialValue: '',\n                rules: [\n                  { required: true, message: 'Konfirmasi kata sandi Anda!' },\n                  { validator: this.checkPassword },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"size":"default","placeholder":"Konfirmasi kata sandi","autocomplete":"new-password"}})],1)],1),_c('a-button',{staticClass:"text-center w-100",class:_vm.$style.submitButton,attrs:{"htmlType":"submit","size":"large","type":"primary","shape":"round","loading":_vm.isLoading}},[_c('strong',{staticClass:"h5"},[_vm._v("Daftar")])]),_c('div',{staticClass:"text-left pt-2 mt-3"},[_c('span',{staticClass:"mr-2"},[_vm._v("Sudah punya akun?")]),_c('router-link',{staticClass:"kit__utils__link font-size-16",attrs:{"to":"/auth/login"}},[_vm._v("Masuk sekarang")])],1)],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"mb-4",attrs:{"src":"resources/images/medisync-withtext.svg","alt":"Medisync","height":"52"}})])}]

export { render, staticRenderFns }